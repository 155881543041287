<script setup lang="ts">
import type { PropType } from "vue";
import type { TSvgName } from "~/lib/svg";

const AlldentalLogoVertical = defineAsyncComponent(() => import("../assets/svg/alldental-logo-vertical.svg"));
const AlldiagLogoVertical = defineAsyncComponent(() => import("../assets/svg/alldiag-logo-vertical.svg"));
const AllestetisLogoVertical = defineAsyncComponent(() => import("../assets/svg/allestetis-logo-vertical.svg"));
const AllmedicaLogoHorizontal = defineAsyncComponent(() => import("../assets/svg/allmedica-logo-horizontal.svg"));
const AllmedicaLogoVertical = defineAsyncComponent(() => import("../assets/svg/allmedica-logo-vertical.svg"));
const Decorator1 = defineAsyncComponent(() => import("../assets/svg/decorator-1.svg"));
const Decorator2 = defineAsyncComponent(() => import("../assets/svg/decorator-2.svg"));
const Decorator3 = defineAsyncComponent(() => import("../assets/svg/decorator-3.svg"));
const Decorator4 = defineAsyncComponent(() => import("../assets/svg/decorator-4.svg"));
const CircleDecorator = defineAsyncComponent(() => import("../assets/svg/circle-decorator.svg"));
const Dots = defineAsyncComponent(() => import("../assets/svg/dots.svg"));
const Heart = defineAsyncComponent(() => import("../assets/svg/heart.svg"));
const Star = defineAsyncComponent(() => import("../assets/svg/star.svg"));
const Quote = defineAsyncComponent(() => import("../assets/svg/quote.svg"));
const Star2 = defineAsyncComponent(() => import("../assets/svg/star-2.svg"));
const StarShine = defineAsyncComponent(() => import("../assets/svg/star-shine.svg"));
const Airport = defineAsyncComponent(() => import("../assets/svg/airport.svg"));
const PhoneLarge = defineAsyncComponent(() => import("../assets/svg/phone-large.svg"));
const Surgery = defineAsyncComponent(() => import("../assets/svg/surgery.svg"));
const ClientReview = defineAsyncComponent(() => import("../assets/svg/client-review.svg"));
const Timer = defineAsyncComponent(() => import("../assets/svg/timer.svg"));
const SmilingFaceWithHeartEyes = defineAsyncComponent(() => import("../assets/svg/smiling-face-with-heart-eyes.svg"));

const { color, width, height } = defineProps({
    name: {
        type: String as PropType<TSvgName>,
        required: true
    },
    color: {
        type: String,
        default: ""
    },
    width: {
        type: String,
        default: "auto"
    },
    height: {
        type: String,
        default: "auto"
    }
});
</script>

<template>
    <div v-if="!name" role="icon"></div>
    <AllmedicaLogoVertical v-else-if="name === 'allmedica-logo-vertical'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <AlldentalLogoVertical v-else-if="name === 'alldental-logo-vertical'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <AllestetisLogoVertical v-else-if="name === 'allestetis-logo-vertical'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <AlldiagLogoVertical v-else-if="name === 'alldiag-logo-vertical'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <AllmedicaLogoHorizontal v-else-if="name === 'allmedica-logo-horizontal'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Star v-else-if="name === 'star'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Decorator1 v-else-if="name === 'decorator-1'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Decorator2 v-else-if="name === 'decorator-2'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Decorator3 v-else-if="name === 'decorator-3'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Decorator4 v-else-if="name === 'decorator-4'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Star2 v-else-if="name === 'star-2'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <StarShine v-else-if="name === 'star-shine'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Heart v-else-if="name === 'heart'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Dots v-else-if="name === 'dots'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <CircleDecorator v-else-if="name === 'circle-decorator'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Quote v-else-if="name === 'quote'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Airport v-else-if="name === 'airport'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <PhoneLarge v-else-if="name === 'phone-large'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Surgery v-else-if="name === 'surgery'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <ClientReview v-else-if="name === 'client-review'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <Timer v-else-if="name === 'timer'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
    <SmilingFaceWithHeartEyes v-else-if="name === 'smiling-face-with-heart-eyes'" :style="{ fill: color, height, width, verticalAlign: 'middle' }" />
</template>
